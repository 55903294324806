import { useEffect as $, useId as F, useState as I } from "react";
import { jsx as r, jsxs as g, Fragment as P } from "react/jsx-runtime";
import { setLoginFlowState as J } from "@vgno/account";
import { getUTMParams as G } from "@vgno/utils";
import { Button as w } from "@vgno/core";
import { Play as C, Replay as W, Pause as Y, AudioLow as K, AudioOff as Q, AudioOn as X } from "@vgno/icons";
import './assets/index.css';const Z = () => {
  try {
    const e = document.querySelector("#overlay-manifest");
    if (!e || !e.textContent)
      throw new Error("Could not find VGTV overlay manifest");
    return JSON.parse(e.textContent);
  } catch (e) {
    return console.warn("Failed to load VGTV overlay manifest", e), null;
  }
}, tt = () => {
  const e = document.createElement("div");
  e.id = "vgtv-overlay", document.body.appendChild(e);
}, et = (e) => {
  try {
    new URL(e);
  } catch {
    throw new Error(`Invalid stylesheet URL: ${e}`);
  }
  const s = document.createElement("link");
  s.rel = "stylesheet", s.href = e, document.head.appendChild(s);
}, st = () => {
  const e = [
    { src: "https://vgc.no/player/player.next.min.bundled-latest.js" },
    { src: "https://vgc.no/player/player-plugin-skin-vgtv2-latest.js" },
    {
      as: "style",
      src: "https://vgc.no/player/player-skin-vgtv2-latest.css"
    }
  ], s = document.createDocumentFragment();
  for (const { as: n = "script", src: t } of e) {
    const o = document.createElement("link");
    o.href = t, o.rel = "preload", o.as = n, s.appendChild(o);
  }
  document.head.appendChild(s);
}, nt = () => "noModule" in HTMLScriptElement.prototype, ot = () => {
  try {
  } catch {
    return !1;
  }
  return !0;
}, at = (e) => e.dataset.useOverlayPlayer === "true", it = (e) => {
  const s = new URL(e);
  history.pushState({}, "", s.pathname + s.search), dispatchEvent(new PopStateEvent("popstate", { state: {} }));
}, rt = async () => {
  if (!ot() || !nt())
    return;
  const e = Z();
  e && (tt(), et(e["vivi.css"]), await import(
    /* @vite-ignore */
    e["vivi.js"]
  ), st(), document.addEventListener("click", (s) => {
    var t;
    const n = (t = s.target) == null ? void 0 : t.closest(
      "a[href*='https://tv.vg.no/video/']"
    );
    n && at(n) && (s.preventDefault(), it(n.href));
  }));
}, se = () => ($(() => {
  rt();
}, []), null);
var ct = (e, s) => async ({
  assetIds: n
}, t) => {
  if (!n.length)
    return { items: [] };
  const o = new URLSearchParams({ assetId: [...n].sort().join(",") });
  return s && o.set("provider", s), (await e("/items", { ...t, searchParams: o })).json();
}, lt = (e, s) => async ({
  assetId: n,
  playlistId: t
}, o) => {
  const c = new URLSearchParams({ assetId: String(n) });
  return t && c.set("playlistId", t), s && c.set("provider", s), (await e("/related-items", {
    ...o,
    searchParams: c
  })).json();
}, dt = (e, s) => ({
  getItems: ct(e, s),
  getRelatedItems: lt(e, s)
}), ut = class extends Error {
  constructor(e) {
    const s = e.status || e.status === 0 ? e.status : "", n = e.statusText || "", t = `${s} ${n}`.trim(), o = t ? `status code ${t}` : "an unknown error";
    super(`Request failed with ${o}`), this.name = "HttpError", this.response = e;
  }
}, mt = () => {
  try {
    if (typeof window < "u" && "fetch" in window)
      return fetch;
    if ("fetch" in globalThis)
      return fetch;
  } catch (e) {
    console.error('failed to resolve "fetch"', e);
  }
}, ft = (e) => {
  if (!e)
    return {
      signal: void 0,
      clear: () => {
      }
    };
  const s = new AbortController(), n = setTimeout(() => {
    s.abort();
  }, e), t = () => {
    clearTimeout(n);
  };
  return {
    signal: s.signal,
    clear: t
  };
}, ht = ({
  baseUrl: e,
  timeout: s,
  fetch: n = mt()
}) => {
  const t = e.replace(/\/$/, "");
  return async (o, c = {}) => {
    const { searchParams: l, timeout: d = s, ...m } = c, i = ft(d);
    let h = `${t}${o}`;
    if (l) {
      const v = new URLSearchParams(l).toString();
      h += `?${v}`;
    }
    const u = await n(h, { ...m, signal: i.signal });
    if (i.clear(), !u.ok)
      throw new ut(u);
    return u;
  };
}, vt = () => `${window.location.origin}/stories`, gt = (e, s) => {
  let n = `${e}/embed`;
  s && (n += `?provider=${s}`);
  const t = document.createElement("iframe");
  return t.setAttribute("allow", "autoplay; web-share"), t.setAttribute("src", n), t.style.position = "fixed", t.style.left = "0px", t.style.right = "0px", t.style.top = "0px", t.style.bottom = "0px", t.style.display = "none", t.style.width = "100%", t.style.height = "100%", t.style.border = "none", t.style.backgroundColor = "#fff", t.className = "stories-overlay", t;
}, x = (e) => {
  try {
    const { pathname: s } = new URL(e), { groups: n } = s.match(/^\/stories\/(?<id>(\d+))/) || {};
    return parseInt((n == null ? void 0 : n.id) || "", 10) || void 0;
  } catch {
    return;
  }
}, pt = class extends EventTarget {
  constructor(e = {}) {
    var s, n;
    super(), this.isIframeReady = !1, this.state = "closed", this.baseUrl = (s = e.baseUrl) != null ? s : vt(), this.items = /* @__PURE__ */ new Map(), this.provider = e.provider, this.storiesApi = dt(
      ht({ baseUrl: `${this.baseUrl}/api`, timeout: (n = e.timeout) != null ? n : 1500 }),
      this.provider
    );
  }
  init() {
    if (this.iframe)
      return;
    this.iframe = gt(this.baseUrl, this.provider), document.body.appendChild(this.iframe);
    const e = (s) => {
      try {
        const n = JSON.parse(s.data);
        switch (n.type) {
          case "overlay/go-back": {
            this.dispatch("overlay/go-back");
            break;
          }
          case "overlay/state": {
            this.dispatch("overlay/state", n.payload);
            break;
          }
          case "overlay/ready": {
            this.isIframeReady = !0;
            break;
          }
        }
      } catch {
      }
    };
    window.addEventListener("message", e), this.removeIframeListener = () => {
      window.removeEventListener("message", e);
    };
  }
  destroy() {
    var e;
    (e = this.removeIframeListener) == null || e.call(this), this.state = "closed", this.isIframeReady = !1, this.iframe && (document.body.removeChild(this.iframe), this.iframe = void 0);
  }
  async preloadItem(e) {
    await this.getItem(e);
  }
  async preloadItems(e) {
    const s = e.map((o) => x(o)).filter((o) => !!o).filter((o) => !this.items.has(o)), n = this.storiesApi.getItems({ assetIds: s }), t = async (o) => {
      try {
        const { items: c } = await n;
        return c.find((l) => l.type === "asset" && l.asset.id === o);
      } catch (c) {
        console.error(c);
        return;
      }
    };
    s.forEach((o) => {
      this.items.set(o, t(o));
    });
  }
  async openItem(e) {
    const s = this.iframe;
    if (!s)
      return !1;
    const n = await this.getItem(e);
    if (!n)
      return !1;
    const t = this.postMessage({
      type: "overlay/set-item",
      payload: { item: n, url: e }
    });
    return t && (this.dispatch("overlay/open"), this.state = "open", s.style.display = "unset"), t;
  }
  getItem(e) {
    const s = x(e);
    if (!s)
      return;
    let n = this.items.get(s);
    return n || (n = this.fetchItem(s), this.items.set(s, n)), n;
  }
  closeOverlay() {
    this.iframe && this.state === "open" && (this.state = "closed", this.iframe.style.display = "none", this.postMessage({ type: "overlay/close" }), this.dispatch("overlay/close"));
  }
  async fetchItem(e) {
    try {
      const { items: s } = await this.storiesApi.getItems({ assetIds: [e] });
      return s.find((n) => n.type === "asset" && n.asset.id === e);
    } catch (s) {
      console.error(s);
      return;
    }
  }
  postMessage(e) {
    return this.iframe && this.iframe.contentWindow && this.isIframeReady ? (this.iframe.contentWindow.postMessage(JSON.stringify(e), "*"), !0) : !1;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch(e, s) {
    const n = s ? new CustomEvent(e, { detail: s }) : new Event(e);
    this.dispatchEvent(n);
  }
};
const wt = () => {
  const { hostname: e } = window.location;
  return e === "stage.vg.no" ? "https://stage.vg.no/stories" : "https://www.vg.no/stories";
}, yt = () => {
  const e = new pt({ baseUrl: wt() });
  let s = document.title, n;
  return window.addEventListener("popstate", () => {
    const { pathname: t } = new URL(location.href);
    t.match(/\/stories\/\d+/) ? e.openItem(location.href) : e.closeOverlay();
  }), e.addEventListener("overlay/open", () => {
    s = document.title, n = document.body.style.getPropertyValue("overflow"), document.body.style.overflow = "hidden";
  }), e.addEventListener("overlay/close", () => {
    s && (document.title = s), n !== void 0 && (document.body.style.overflow = n), s = "", n = void 0;
  }), e.addEventListener("overlay/state", ({ detail: t }) => {
    const { path: o, title: c } = t;
    window.history.replaceState({}, "", window.location.origin + o), document.title = c;
  }), e.addEventListener("overlay/go-back", () => {
    history.back();
  }), e;
}, kt = () => {
  const e = window.__stories_overlay ?? yt();
  window.__stories_overlay = e, e.init();
  const s = async (n) => {
    const { pathname: t, search: o } = new URL(n), c = t + o;
    await e.openItem(n) ? history.pushState({}, "", window.location.origin + c) : window.location.href = n;
  };
  document.addEventListener("click", (n) => {
    var o;
    const t = (o = n.target) == null ? void 0 : o.closest(
      "a[href*='https://www.vg.no/stories/']"
    );
    t && (n.preventDefault(), s(t.href));
  });
}, ne = () => ($(() => {
  kt();
}, []), null), oe = () => window.__stories_overlay, q = "https://www.vg.no/vgc/player/player.next.min.bundled-latest.js";
let M = !1;
const bt = () => {
  if (!window || M)
    return;
  M = !0;
  const e = document.createElement("script");
  e.src = q, e.async = !0, document.head.appendChild(e);
}, U = (e) => new Promise((s) => {
  window[e] ? s(window[e]) : window.addEventListener("identity-initialized", () => {
    s(window[e]);
  });
}), Pt = async (e) => {
  const { id: s, assetType: n } = e.rawAsset, t = n === "video" ? `https://api.vg.no/svp/token/v2/${s}?access=plus` : `https://api.vg.no/svp/token/${s}?access=plus`;
  try {
    const o = await fetch(t, {
      credentials: "include"
    });
    if (!o.ok)
      throw new Error("Failed to fetch user status");
    const c = await o.json();
    return { hasProduct: c.hasAccess, loggedIn: c.loggedIn };
  } catch {
    return { hasProduct: !1, loggedIn: !1 };
  }
}, _t = (e = bt) => {
  let s = [], n = !1;
  const t = (d) => {
    const m = window.SVP.getPlayerConfig(
      {
        site: "vg",
        ads: {
          bumper: !0,
          midroll: !0,
          pausead: !0
        },
        paywallAccess: {
          enabled: !0,
          getSpidIdentityInstance: () => U("Identity"),
          getSpidMonetizationInstance: () => U("SPiD_Monetization"),
          message: {
            utmParameters: G(window.location.href, !0),
            onLoginClick: () => {
              var h, u;
              const i = J();
              (u = window.Identity) == null || u.login({
                redirectUri: (h = window.Identity) == null ? void 0 : h.redirectUri,
                state: i
              });
            }
          }
        },
        ...d.options
      },
      {
        userStatus: Pt,
        ...d.config
      }
    );
    return new window.SVP.Player(m);
  }, o = () => typeof window.SVP < "u" && !!window.SVP.Player;
  return {
    initLibrary: () => {
      n || (n = !0, window.addEventListener("onSvpPlayerReady", () => {
        s.forEach((d) => d()), s = [];
      }), e());
    },
    loadVideo: (d) => new Promise((m) => {
      o() ? m(t(d)) : s.push(() => m(t(d)));
    })
  };
}, O = _t(), H = () => {
  if (!(typeof window > "u"))
    return O.initLibrary(), O.loadVideo;
}, $t = "_playerContainer_tm6eo_1", Rt = "_podcastContainer_tm6eo_12", V = {
  playerContainer: $t,
  podcastContainer: Rt
}, L = {
  audio: "https://vgc.no/player/player-skin-vgtv-latest.css",
  video: "https://vgc.no/player/player-skin-vgtv2-latest.css"
}, It = (e) => `https://www.vg.no/nyheter/i/jPJ5g9/vg?utm_content=kjopvgpluss&utm_source=vgno&asset_type=video&asset_id=${e}&asset_provider=vgtv`, ae = ({
  assetId: e,
  assetType: s = "video",
  autoplayMuted: n = !1,
  className: t,
  context: o,
  enableAutoPlay: c = !1,
  loading: l = "lazy",
  loop: d = !1,
  noFirstAd: m = !1,
  videoPreview: i = !1,
  vendor: h = "vgtv",
  ...u
}) => {
  const v = H(), _ = F(), a = `svp-video-${e}-${_}`, f = {
    name: s,
    url: s in L ? L[s] : L.video
  };
  return $(() => {
    const p = {
      context: o,
      noFirstAd: m
    }, k = {
      autoplay: c,
      id: Number(e),
      mute: n,
      node: a,
      repeat: d,
      settings: {
        na: s === "audio"
        // na = no ads
      },
      skin: f.name === "video" ? void 0 : f,
      subscriptionUrl: It(e),
      vendor: h,
      videoPreview: i
    };
    if (l === "eager") {
      v == null || v({
        config: k,
        options: p
      });
      return;
    }
    const y = new IntersectionObserver(
      (R) => {
        R[0].isIntersecting && (y.disconnect(), v == null || v({
          config: k,
          options: p
        }));
      },
      {
        rootMargin: "100%"
      }
    );
    return y.observe(document.getElementById(a)), () => y.disconnect();
  }, [e, v, c, n, l, d]), /* @__PURE__ */ r(
    "div",
    {
      ...u,
      className: t || (f.name === "audio" ? V.podcastContainer : V.playerContainer),
      children: /* @__PURE__ */ r("div", { id: a })
    }
  );
}, ie = [
  {
    as: "style",
    href: L.video
  },
  {
    as: "script",
    href: q
  }
], Ct = "_text_1abvd_1", St = {
  text: Ct
}, Lt = () => /* @__PURE__ */ r("p", { className: St.text, children: "ANNONSE" }), Et = "_duration_uj4c6_1", Nt = {
  duration: Et
}, B = (e) => {
  const s = Math.floor(e / 60), n = Math.floor(e % 60), t = n < 10 ? `0${n}` : n;
  return `${s < 10 ? `0${s}` : s}:${t}`;
}, xt = ({ currentTime: e, duration: s, className: n }) => /* @__PURE__ */ g(
  "p",
  {
    role: "timer",
    "aria-live": "off",
    className: `label-small font-inter label-secondary ${Nt.duration} ${n}`,
    children: [
      B(e),
      " / ",
      B(s)
    ]
  }
), Mt = "_srOnly_1249o_1", Ut = "_introduction_1249o_13", A = {
  srOnly: Mt,
  introduction: Ut
}, T = (e) => {
  if (e < 60)
    return `${Math.round(e / 10) * 10} sekunder`;
  {
    const s = Math.round(e / 60);
    return `${s} minutt${s === 1 ? "" : "er"}`;
  }
}, j = ({ duration: e }) => /* @__PURE__ */ g(P, { children: [
  /* @__PURE__ */ g("p", { "aria-hidden": "true", className: A.introduction, children: [
    /* @__PURE__ */ r("strong", { children: "Lytt til saken" }),
    !!e && /* @__PURE__ */ g(P, { children: [
      " ",
      /* @__PURE__ */ r("span", { children: "•" }),
      " ",
      /* @__PURE__ */ r("span", { children: T(e) })
    ] })
  ] }),
  /* @__PURE__ */ r("p", { className: A.srOnly, children: !!e && `Avspilling har en varighet på ${T(e)}.` })
] }), Ot = "_button_vec52_1", b = {
  button: Ot
}, z = ({
  state: e,
  isApp: s,
  vendor: n,
  assetId: t,
  start: o,
  pause: c
}) => s ? /* @__PURE__ */ r(
  w,
  {
    "data-track-click-intent": "Play",
    "data-track-element-type": "Play Button",
    size: "large",
    style: "filled",
    "aria-label": "Lytt til denne saken",
    href: `audio:${n}:${t}`,
    "data-track-no-utm": !0,
    className: b.button,
    children: /* @__PURE__ */ r(C, {})
  }
) : /* @__PURE__ */ r(P, { children: (() => {
  switch (e) {
    case "buffering":
    case "loading":
    case "stalled":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Attempt",
          "data-track-element-type": "Play Button Disabled",
          size: "large",
          style: "filled",
          "aria-disabled": "true",
          "aria-label": "Laster inn lytt til saken avspiller",
          className: b.button,
          children: /* @__PURE__ */ r(C, {})
        }
      );
    case "playing":
    case "adPlaying":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Pause",
          "data-track-element-type": "Pause Button",
          size: "large",
          style: "filled",
          "aria-label": "Pause avspilling av saken",
          onClick: () => c(),
          className: b.button,
          children: /* @__PURE__ */ r(Y, {})
        }
      );
    case "complete":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Play",
          "data-track-element-type": "Rewatch Button",
          size: "large",
          style: "filled",
          "aria-label": "Start avspilling av saken på nytt",
          onClick: () => o(),
          className: b.button,
          children: /* @__PURE__ */ r(W, {})
        }
      );
    case "idle":
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Play",
          "data-track-element-type": "Play Button",
          size: "large",
          style: "filled",
          "aria-label": "Lytt til denne saken",
          onClick: () => o(),
          className: b.button,
          children: /* @__PURE__ */ r(C, {})
        }
      );
    default:
      return /* @__PURE__ */ r(
        w,
        {
          "data-track-click-intent": "Resume",
          "data-track-element-type": "Resume Button",
          size: "large",
          style: "filled",
          "aria-label": "Lytt til denne saken",
          onClick: () => o(),
          className: b.button,
          children: /* @__PURE__ */ r(C, {})
        }
      );
  }
})() }), Vt = "_playbackRate_1qo2p_1", Bt = {
  playbackRate: Vt
}, E = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3], At = ({ playbackRate: e, setPlaybackRate: s }) => {
  const n = E[(E.indexOf(e) + 1) % E.length];
  return /* @__PURE__ */ g(
    w,
    {
      className: Bt.playbackRate,
      style: "filled",
      size: "small",
      shape: "capsule",
      "aria-label": `Endre avspillingshastighet til ${n}x`,
      onClick: () => s(n),
      "data-track-click-intent": "Update",
      "data-track-element-type": `Playback Rate Button ${n}`,
      children: [
        e,
        "x"
      ]
    }
  );
}, Tt = "_seekSlider_it4ij_1", jt = {
  seekSlider: Tt
}, D = (e) => {
  const s = Math.floor(e / 60), n = Math.floor(e % 60);
  return `${s} minutter, ${n} sekunder`;
}, zt = ({
  currentTime: e,
  duration: s,
  disableSeeking: n,
  seek: t
}) => {
  const o = e / s * 100;
  return /* @__PURE__ */ r(
    "input",
    {
      className: jt.seekSlider,
      style: {
        background: `linear-gradient(to right, var(--accent) ${o}%, #ccc ${o}%)`
      },
      onChange: (c) => {
        n || t(Number(c.target.value));
      },
      type: "range",
      id: "seek-slider",
      "aria-label": "Søkelinje",
      "aria-disabled": n,
      "aria-valuetext": `${D(e)} av ${D(s)}`,
      value: e,
      max: s,
      min: 0,
      "data-track-click-intent": "Show",
      "data-track-element-type": "Seek Slider"
    }
  );
}, Dt = "_wrapper_13ik5_1", Ft = "_volumeButton_13ik5_5", qt = "_volumeSlider_13ik5_13", N = {
  wrapper: Dt,
  volumeButton: Ft,
  volumeSlider: qt
}, Ht = ({ setVolume: e, volume: s, mute: n, muted: t }) => {
  const o = s < 25 && !t, c = s === 0 || t, l = s >= 25 && !t, d = () => {
    t && s === 0 && e(100), n(!t);
  };
  return /* @__PURE__ */ g("div", { className: N.wrapper, children: [
    /* @__PURE__ */ r(
      "input",
      {
        className: N.volumeSlider,
        style: {
          background: `linear-gradient(to right, var(--accent) ${s}%, #ccc ${s}%)`
        },
        onChange: (m) => e(Number(m.target.value)),
        type: "range",
        id: "volume-slider",
        "aria-label": "Volumkontroll",
        "aria-valuetext": `Volum ${s}%`,
        value: s,
        max: 100,
        min: 0,
        "data-track-click-intent": "Update",
        "data-track-element-type": "Volume Slider"
      }
    ),
    /* @__PURE__ */ g(
      w,
      {
        className: N.volumeButton,
        "aria-label": t ? "Slå på lyd" : "Slå av lyd",
        style: "filled",
        size: "small",
        shape: "capsule",
        onClick: d,
        "data-track-click-intent": "Update",
        "data-track-element-type": t ? "Volume On" : "Volume Off",
        children: [
          o && /* @__PURE__ */ r(K, {}),
          c && /* @__PURE__ */ r(Q, {}),
          l && /* @__PURE__ */ r(X, {})
        ]
      }
    )
  ] });
}, Jt = "_svpContainer_8u983_1", Gt = "_controls_8u983_5", Wt = "_durationInfo_8u983_11", Yt = "_duration_8u983_11", S = {
  svpContainer: Jt,
  controls: Gt,
  durationInfo: Wt,
  duration: Yt
}, re = ({
  assetId: e,
  vendor: s = "vgtv",
  isApp: n
}) => {
  const [t, o] = I(null), [c, l] = I(!1), [d, m] = I(!1), [i, h] = I(null), u = H(), v = F(), _ = `svp-video-${e}-${v}`;
  return $(() => {
    let a = null;
    return (async () => {
      const p = await (u == null ? void 0 : u({
        config: {
          vendor: s,
          node: _,
          autoplay: !1,
          id: Number(e),
          mute: !1,
          repeat: !1,
          locale: "no"
        }
      }));
      p && (a = p, o(p));
    })(), () => {
      a && a.remove();
    };
  }, [e, u, _, s]), $(() => {
    const a = (k = !1) => {
      var R;
      if (!k && !c) return;
      const y = ((R = t == null ? void 0 : t.getRawAsset()) == null ? void 0 : R.duration) ?? 0;
      h({
        currentTime: (t == null ? void 0 : t.getCurrentTime()) ?? 0,
        duration: (t == null ? void 0 : t.getDuration()) || y / 1e3,
        state: (t == null ? void 0 : t.getState()) ?? "idle",
        playbackRate: (t == null ? void 0 : t.getPlaybackRate()) ?? 1,
        isMuted: (t == null ? void 0 : t.getMute()) ?? !1,
        volume: (t == null ? void 0 : t.getVolume()) ?? 0
      });
    }, f = (k, y) => {
      h({
        currentTime: k,
        duration: y,
        state: (t == null ? void 0 : t.getState()) ?? "idle",
        isMuted: (t == null ? void 0 : t.getMute()) ?? !1,
        playbackRate: (t == null ? void 0 : t.getPlaybackRate()) ?? 1,
        volume: (t == null ? void 0 : t.getVolume()) ?? 0
      });
    }, p = () => {
      m(!0), a();
    };
    return t == null || t.on("assetPlay", a), t == null || t.on("assetReady", a), t == null || t.on("complete", a), t == null || t.on("error", a), t == null || t.on("mute", a), t == null || t.on("pause", a), t == null || t.on("play", a), t == null || t.on("playNext", a), t == null || t.on("volume", a), t == null || t.on("adFinished", a), t == null || t.on("adPause", a), t == null || t.on("adPlay", a), t == null || t.on("adStarted", a), t == null || t.on("playbackRateChanged", () => console.log("playbackRateChanged")), t == null || t.on("ready", () => {
      l(!0), a(!0);
    }), t == null || t.on("initialPlay", p), t == null || t.on("adProgress", f), t == null || t.on("time", f), () => {
      t == null || t.off("assetPlay", a), t == null || t.off("assetReady", a), t == null || t.off("complete", a), t == null || t.off("error", a), t == null || t.off("initialPlay", p), t == null || t.off("mute", a), t == null || t.off("pause", a), t == null || t.off("play", a), t == null || t.off("playNext", a), t == null || t.off("ready", a), t == null || t.off("volume", a), t == null || t.off("time", f), t == null || t.off("adProgress", f), t == null || t.off("adFinished", a), t == null || t.off("adPause", a), t == null || t.off("adPlay", a), t == null || t.off("adStarted", a), t == null || t.off("adProgress", f);
    };
  }, [t, c]), /* @__PURE__ */ g(P, { children: [
    /* @__PURE__ */ r("div", { className: S.svpContainer, children: /* @__PURE__ */ r("div", { id: _ }) }),
    /* @__PURE__ */ r("div", { className: S.controls, children: c && !n ? /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ r(
        z,
        {
          state: (i == null ? void 0 : i.state) ?? "idle",
          start: () => t == null ? void 0 : t.play(),
          pause: () => t == null ? void 0 : t.pause()
        }
      ),
      d ? /* @__PURE__ */ g("div", { className: S.durationInfo, children: [
        /* @__PURE__ */ r(
          zt,
          {
            disableSeeking: (i == null ? void 0 : i.state) === "adPlaying",
            currentTime: (i == null ? void 0 : i.currentTime) ?? 0,
            duration: (i == null ? void 0 : i.duration) ?? 0,
            seek: (a) => t == null ? void 0 : t.seek(a)
          }
        ),
        (i == null ? void 0 : i.state) === "adPlaying" && /* @__PURE__ */ r(Lt, {}),
        /* @__PURE__ */ r(
          xt,
          {
            className: S.duration,
            currentTime: (i == null ? void 0 : i.currentTime) ?? 0,
            duration: (i == null ? void 0 : i.duration) ?? 0
          }
        )
      ] }) : /* @__PURE__ */ r(j, { duration: (i == null ? void 0 : i.duration) || 0 }),
      /* @__PURE__ */ r(
        Ht,
        {
          muted: (i == null ? void 0 : i.isMuted) ?? !1,
          mute: (a) => t == null ? void 0 : t.setMute(a),
          volume: (i == null ? void 0 : i.volume) ?? 100,
          setVolume: (a) => t == null ? void 0 : t.setVolume(a)
        }
      ),
      /* @__PURE__ */ r(
        At,
        {
          playbackRate: (i == null ? void 0 : i.playbackRate) ?? 1,
          setPlaybackRate: (a) => {
            h((f) => ({
              ...f,
              playbackRate: a
            })), t == null || t.setPlaybackRate(a);
          }
        }
      )
    ] }) : /* @__PURE__ */ g(P, { children: [
      /* @__PURE__ */ r(
        z,
        {
          isApp: n,
          vendor: s,
          assetId: e,
          state: "loading",
          start: () => {
          },
          pause: () => {
          }
        }
      ),
      /* @__PURE__ */ r(j, { duration: (i == null ? void 0 : i.duration) || 0 })
    ] }) })
  ] });
};
export {
  se as OverlayPlayer,
  ne as StoriesOverlay,
  re as TTSPlayer,
  ae as Video,
  oe as getStoriesOverlay,
  it as openOverlay,
  ie as preloadAssets
};
